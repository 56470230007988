import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";

import { ToggleComponentProps } from "./types";
import { OrangeSelect } from "../mui-theme/select";
import { Apis } from "@orangep/util";

const OrderingText: Record<Apis.SortOrder, string> = {
  [Apis.SortOrder.Asc]: "น้อยไปมาก",
  [Apis.SortOrder.Desc]: "มากไปน้อย",
};

export function OrderingTypeFilter({
  selected,
  onChange,
}: ToggleComponentProps) {
  return (
    <FormControl fullWidth>
      <OrangeSelect
        value={selected}
        onChange={(e) => onChange(e.target.value)}
        size="small"
        data-testid="ordering-type-filter"
      >
        {Object.entries(OrderingText).map(([value, label]) => (
          <MenuItem
            key={value}
            value={value}
            data-testid="order-type-filter-option"
          >
            {label}
          </MenuItem>
        ))}
      </OrangeSelect>
    </FormControl>
  );
}
