import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import { FilterButton } from "../buttons";
import { Collapse } from "@mui/material";

type FilterBarProps = {
  visible: boolean;
  onFilterClick: () => void;
};

export function FilterBar({ visible, onFilterClick }: FilterBarProps) {
  return (
    <Grid item xs={12}>
      <Collapse in={visible}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          {visible ? <FilterButton onClick={onFilterClick} /> : null}
        </Box>
      </Collapse>
    </Grid>
  );
}
