import { Apis, getAxiosInstance } from "@orangep/util";
import { QueryFunctionContext } from "@tanstack/react-query";
import { jsonToGraphQLQuery, EnumType } from "json-to-graphql-query";

type UpdateRoleArgs = {
  userId: string;
  roleCode: string;
};

type UpdateUserDataArgs = {
  id: string;
  code: string;
  name: string;
};

const UserRoleFields = {
  id: true,
  roleCode: true,
};

const LineUserFields = {
  id: true,
  displayName: true,
  pictureUrl: true,
  isFriendship: true,
};

const UserFields = {
  id: true,
  code: true,
  name: true,
  isDeleted: true,
  lineUser: LineUserFields,
  userRoles: UserRoleFields,
};

export function listUsers({
  queryKey: [_, where = null, orderBy = null, take = 20],
  pageParam,
}: QueryFunctionContext) {
  const order_by: any = orderBy;
  const args: any = {
    take,
    skip: pageParam || 0,
  };

  if (where) {
    args.where = where;
  }
  if (orderBy && (order_by.code || order_by.name)) {
    let orderByField: any = undefined;
    if (order_by?.code) {
      orderByField = { code: new EnumType(order_by.code) };
    }

    if (order_by?.name) {
      orderByField = { name: new EnumType(order_by.name) };
    }

    args.orderBy = orderByField;
  }

  const query = jsonToGraphQLQuery(
    {
      query: {
        users: {
          __args: {
            ...args,
            // // where,
            // orderBy: orderByField,
            // take,
            // skip: pageParam || 0,
          },
          ...UserFields,
        },
      },
    },
    { pretty: true }
  );

  return getAxiosInstance()
    .request({
      data: { query },
    })
    .then((response) => response.data);
}

export function updateUser(data: UpdateUserDataArgs) {
  const query = jsonToGraphQLQuery(
    {
      mutation: {
        updateUser: {
          __args: {
            data: {
              code: { set: data.code },
              name: { set: data.name },
            },
            where: {
              id: data.id,
            },
          },
          ...UserFields,
        },
      },
    },
    { pretty: true }
  );
  return getAxiosInstance()
    .request({
      data: { query },
    })
    .then((response) => response.data);
}

export function disconnectUserRole(data: UpdateRoleArgs) {
  const query = jsonToGraphQLQuery(
    {
      mutation: {
        updateUser: {
          __args: {
            data: {
              userRoles: {
                delete: {
                  userId_roleCode: {
                    roleCode: data.roleCode,
                    userId: data.userId,
                  },
                },
              },
            },
            where: {
              id: data.userId,
            },
          },
          ...UserFields,
        },
      },
    },
    { pretty: true }
  );
  return getAxiosInstance()
    .request({
      data: { query },
    })
    .then((response) => response.data);
}

export function connectUserRole(data: UpdateRoleArgs) {
  const query = jsonToGraphQLQuery(
    {
      mutation: {
        updateUser: {
          __args: {
            data: {
              userRoles: {
                create: {
                  roleCode: data.roleCode,
                },
              },
            },
            where: {
              id: data.userId,
            },
          },
          ...UserFields,
        },
      },
    },
    { pretty: true }
  );

  return getAxiosInstance()
    .request({
      data: { query },
    })
    .then((response) => response.data);
}

export function blockUserById(id: string) {
  return getAxiosInstance()
    .request({
      data: {
        query: jsonToGraphQLQuery({
          mutation: {
            blockUser: {
              __args: {
                where: {
                  id: id,
                },
              },
              id: true,
            },
          },
        }),
      },
    })
    .then((response) => response.data);
}

export function unblockUserById(id: string) {
  return getAxiosInstance()
    .request({
      data: {
        query: jsonToGraphQLQuery({
          mutation: {
            unblockUser: {
              __args: {
                where: {
                  id: id,
                },
              },
              id: true,
            },
          },
        }),
      },
    })
    .then((response) => response.data);
}
