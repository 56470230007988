import styled from "@mui/material/styles/styled";
import Select from "@mui/material/Select";

export const OrangeSelect = styled(Select)(({ theme }) => ({
  "&.Mui-focused": {
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: theme.status.danger,
    },
    svg: {
      color: theme.status.danger,
    },
  },
}));
