import { useState, useMemo, useEffect, lazy, Suspense } from "react";
import debounce from "lodash/debounce";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";

import InfiniteScroll from "react-infinite-scroller";

import {
  Apis,
  createConfirmationDialog,
  createNotify,
  isAccessible$,
  RoleType,
  setPageTitle,
} from "@orangep/util";
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { useObservableState } from "observable-hooks";
import {
  blockUserById,
  connectUserRole,
  disconnectUserRole,
  listUsers,
  unblockUserById,
  updateUser,
} from "../helpers/api";
// import UserCard from "../components/user-card";
const UserCard = lazy(
  () => import(/* webpackChunkName: "user-card" */ "../components/user-card")
);

import { OrangeTextField } from "../components/mui-theme";
import { Collapse, Typography } from "@mui/material";
import { EnumType } from "json-to-graphql-query";

import { FieldOrderingFilter } from "../components/filters/field-ordering-filter";
import { OrderingTypeFilter } from "../components/filters/ordering-type-filter";
import { FilterBar } from "../components/filters/filter-bar";
import { CloseButton } from "../components/buttons";
import { UserTypeFilter } from "../components/filters/user-type-filter";

type DeleteUserData = {
  id: string;
  code: string;
  name: string;
};

const ROWS_PER_PAGE = 6;

const UserHomePage = ({ singleSpa }) => {
  const isReadable = useObservableState(
    isAccessible$([
      RoleType.USER_VIEW,
      RoleType.MAIN_VIEW,
      RoleType.MAIN_MASTER,
    ]),
    false
  );
  const isWritable = useObservableState(
    isAccessible$([RoleType.USER_MASTER, RoleType.MAIN_MASTER]),
    false
  );

  const isMainMaster = useObservableState(
    isAccessible$([RoleType.MAIN_MASTER]),
    false
  );

  const [filterVisible, setFilterVisible] = useState<boolean>(false);
  const [searchText, setSearchText] = useState("");
  const [orderField, setOrderField] = useState<OrderingField>("code");
  const [ordering, setOrdering] = useState<Apis.SortOrder>(Apis.SortOrder.Asc);
  const [userType, setUserType] = useState<UserTypeFilter>("USER");
  const [errorText, setErrorText] = useState<string | null>(null);

  const [userToBeDeleted, setUserToBeDeleted] = useState<DeleteUserData | null>(
    null
  );

  const [where, setWhere] = useState(null);

  const queryClient = useQueryClient();

  setPageTitle("ผู้ใช้งานระบบ");

  const connectRoleMutation = useMutation({
    mutationFn: connectUserRole,
    onSuccess: (data) => {
      updateQueryCache(data.data.updateUser);
    },
  });

  const disconnectRoleMutation = useMutation({
    mutationFn: disconnectUserRole,
    onSuccess: (data) => {
      updateQueryCache(data.data.updateUser);
    },
  });

  const updateUserMutation = useMutation({
    mutationFn: updateUser,
    onSuccess: (data) => {
      updateQueryCache(data.data.updateUser);
      setErrorText(null);
    },
    onError: () => {
      setErrorText("ไม่สามารถแก้ไขข้อมูลผู้ใช้งานได้");
    },
  });

  const blockUserMutation = useMutation({
    mutationFn: blockUserById,
    onSuccess: async (data) => {
      await refetch();
      createNotify("ลบผู้ใช้งานแล้ว", "info");
    },
  });

  const unblockUserMutation = useMutation({
    mutationFn: unblockUserById,
    onSuccess: async (data) => {
      await refetch();
      createNotify("ผู้ใช้ถูกย้ายไปเป็นผู้เยี่ยมชมแล้ว", "info");
      setUserType("GUEST");
    },
  });

  const { data, fetchNextPage, hasNextPage, isFetching, refetch } =
    useInfiniteQuery({
      queryFn: listUsers,
      queryKey: ["users", where, { [orderField]: ordering }, ROWS_PER_PAGE],
      enabled: isReadable,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.data.users.length < ROWS_PER_PAGE) return undefined;
        return pages.length * ROWS_PER_PAGE;
      },
    });

  const updateQueryCache = (user: any) => {
    const pages = data?.pages || [];

    const updatedPages = pages.map((p) => {
      const users = p.data.users.map((u) => {
        if (u.id === user.id) {
          return user;
        } else {
          return { ...u };
        }
      });
      return {
        ...p,
        data: {
          ...p.data,
          users,
        },
      };
    });

    // update to query cache
    queryClient.setQueryData(
      ["users", where, { [orderField]: ordering }, ROWS_PER_PAGE],
      {
        ...data,
        pages: updatedPages,
      }
    );
  };

  const debounceSearchParam = useMemo(() => {
    return debounce((searchParam: string, userType: UserTypeFilter) => {
      let _where = null;

      if (searchParam) {
        _where = {
          ..._where,
          OR: [
            {
              code: {
                contains: searchParam,
                mode: new EnumType("insensitive"),
              },
            },
            {
              name: {
                contains: searchParam,
                mode: new EnumType("insensitive"),
              },
            },
          ],
        };
      }

      if (!searchParam) {
        if (userType === "USER") {
          _where = {
            ..._where,
            isDeleted: {
              equals: false,
            },
            userRoles: {
              some: {
                roleCode: {
                  contains: "_",
                },
              },
            },
          };
        }

        if (userType === "GUEST") {
          _where = {
            ..._where,
            isDeleted: {
              equals: false,
            },
            userRoles: {
              none: {
                roleCode: {
                  contains: "_",
                },
              },
            },
          };
        }

        if (userType === "DELETED") {
          _where = {
            ...where,
            isDeleted: {
              equals: true,
            },
          };
        }
      }
      setWhere({
        ..._where,
      });
    }, 500);
  }, []);

  useEffect(() => {
    debounceSearchParam(searchText, userType);
  }, [searchText, userType, debounceSearchParam]);

  useEffect(() => {
    return () => {
      debounceSearchParam.cancel();
    };
  }, [debounceSearchParam]);

  useEffect(() => {
    if (userToBeDeleted) {
      // singleSpa.navigateToUrl(`/users/${userIdToBeDeleted.id}/delete`);
      createConfirmationDialog({
        confirmationText: userToBeDeleted.code,
        data: userToBeDeleted,
        onConfirm: (data) => {
          blockUserMutation.mutate(data.id);
        },
        onCancel: () => {
          setUserToBeDeleted(null);
        },
      });
    }
  }, [userToBeDeleted]);

  const users = data?.pages?.map((p) => p.data.users).flat() || [];

  return (
    <Suspense fallback="Loading...">
      <InfiniteScroll
        loadMore={() => {
          fetchNextPage();
        }}
        loader={
          <Box
            key={new Date().getTime()}
            data-testid="infinite-scroll-loader"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              pt: 2,
            }}
          >
            <CircularProgress />
          </Box>
        }
        hasMore={hasNextPage}
      >
        <Grid container data-testid="user-page-wrapper">
          <FilterBar
            visible={!filterVisible}
            onFilterClick={() => {
              setFilterVisible(true);
            }}
          />
          <Grid item xs={12} p={filterVisible ? 1 : null}>
            <Collapse in={filterVisible}>
              <Paper sx={{ p: 2 }} data-testid="filter-bar">
                <Grid
                  item
                  container
                  spacing={1}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid item xs={4} md="auto">
                    <Typography px={1}>ค้นหา</Typography>
                  </Grid>
                  <Grid item xs={8} md="auto">
                    <OrangeTextField
                      value={searchText}
                      placeholder="ชื่อ/รหัสผู้ใช้"
                      size="small"
                      fullWidth
                      inputProps={{
                        "data-testid": "search-text-field",
                      }}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={4} md="auto">
                    <Typography px={1}>เรียงลำดับ</Typography>
                  </Grid>
                  <Grid item xs={8} md="auto">
                    <FieldOrderingFilter
                      selected={orderField}
                      onChange={(value) => setOrderField(value)}
                    />
                  </Grid>

                  <Grid item xs={4} md="auto">
                    <Typography px={1}>จาก</Typography>
                  </Grid>
                  <Grid item xs={8} md="auto">
                    <OrderingTypeFilter
                      selected={ordering}
                      onChange={(value) => setOrdering(value)}
                    />
                  </Grid>

                  <Grid item xs={4} md="auto">
                    <Typography px={1}>สถานะผู้ใช้งาน</Typography>
                  </Grid>
                  <Grid item xs={8} md="auto">
                    <UserTypeFilter
                      selected={userType}
                      onChange={(value) => setUserType(value)}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md="auto"
                    container
                    justifyContent="flex-end"
                  >
                    <Grid item>
                      <CloseButton
                        onClick={() => {
                          setFilterVisible(false);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Collapse>
          </Grid>
          {users.map((user) => {
            return (
              <Grid item xs={12} md={4} key={user.id} p={1}>
                <UserCard
                  user={user}
                  editable={isWritable}
                  allowMainRoleChange={isMainMaster}
                  errorText={errorText}
                  onUserInfoChange={(code, name) => {
                    updateUserMutation.mutate({
                      id: user.id,
                      code,
                      name,
                    });
                  }}
                  onRoleChange={(roleCode, isChecked) => {
                    let mutation;
                    if (isChecked) {
                      mutation = connectRoleMutation;
                    } else {
                      mutation = disconnectRoleMutation;
                    }
                    mutation.mutate({
                      userId: user.id,
                      roleCode,
                    });
                  }}
                  onDeleteUser={() => {
                    setUserToBeDeleted({
                      id: user.id,
                      code: user.code,
                      name: user.name,
                    });
                  }}
                  onRestoreUser={() => {
                    unblockUserMutation.mutate(user.id);
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </InfiniteScroll>
    </Suspense>
  );
};

export default UserHomePage;
