import UserHomePage from "./pages/home";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { theme } from "./components/mui-theme/mui-theme";

const queryClient = new QueryClient();

export default function Root(props) {
  return (
    <section>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <UserHomePage singleSpa={props.singleSpa} />
        </ThemeProvider>
      </QueryClientProvider>
    </section>
  );
}
