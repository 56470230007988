import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";

import { ToggleComponentProps } from "./types";
import { OrangeSelect } from "../mui-theme/select";

const OrderingFieldText: Record<UserTypeFilter, string> = {
  USER: "ผู้ใช้งานระบบ",
  GUEST: "ผู้เยี่ยมชม",
  DELETED: "ลบแล้ว",
};

export function UserTypeFilter({ selected, onChange }: ToggleComponentProps) {
  return (
    <FormControl fullWidth>
      <OrangeSelect
        value={selected}
        onChange={(e) => onChange(e.target.value)}
        size="small"
        data-testid="user-type-filter"
      >
        {Object.entries(OrderingFieldText).map(([value, label]) => (
          <MenuItem
            key={value}
            value={value}
            data-testid="user-type-filter-option"
          >
            {label}
          </MenuItem>
        ))}
      </OrangeSelect>
    </FormControl>
  );
}
