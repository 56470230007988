import styled from "@mui/material/styles/styled";
import TextField from "@mui/material/TextField";

export const OrangeTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: theme.status.danger,
    },
  },
}));
