import { OrangeIconButton } from "./mui-theme";
import IconButton from "@mui/material/IconButton";

import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import RestoreIcon from "@mui/icons-material/Restore";

export const EditButton = ({ onClick, ...props }) => {
  return (
    <OrangeIconButton aria-label="edit" onClick={onClick} {...props}>
      <EditIcon />
    </OrangeIconButton>
  );
};

export const CloseButton = ({ onClick, ...props }) => {
  return (
    <IconButton aria-label="close" onClick={onClick} {...props}>
      <CloseIcon />
    </IconButton>
  );
};

export const DeleteIconButton = ({ onClick, ...props }) => {
  return (
    <IconButton aria-label="delete" onClick={onClick} {...props}>
      <CancelIcon />
    </IconButton>
  );
};

export const RestoreIconButton = ({ onClick, ...props }) => {
  return (
    <IconButton aria-label="restore" onClick={onClick} {...props}>
      <RestoreIcon />
    </IconButton>
  );
};

export const SaveButton = ({ onClick, ...props }) => {
  return (
    <OrangeIconButton aria-label="delete" onClick={onClick} {...props}>
      <SaveIcon />
    </OrangeIconButton>
  );
};

export const FilterButton = ({ onClick, ...props }) => {
  return (
    <OrangeIconButton aria-label="filter" onClick={onClick} {...props}>
      <FilterAltIcon />
    </OrangeIconButton>
  );
};
