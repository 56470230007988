import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";

import { ToggleComponentProps } from "./types";
import { OrangeSelect } from "../mui-theme/select";

const OrderingFieldText: Record<OrderingField, string> = {
  code: "รหัสพนักงาน",
  name: "ชื่อพนักงาน",
};

export function FieldOrderingFilter({
  selected,
  onChange,
}: ToggleComponentProps) {
  return (
    <FormControl fullWidth>
      <OrangeSelect
        value={selected}
        onChange={(e) => onChange(e.target.value)}
        size="small"
        data-testid="ordering-field-filter"
      >
        {Object.entries(OrderingFieldText).map(([value, label]) => (
          <MenuItem
            key={value}
            value={value}
            data-testid="ordering-field-filter-option"
          >
            {label}
          </MenuItem>
        ))}
      </OrangeSelect>
    </FormControl>
  );
}
